/*------------------------
Helper Class -2
-------------------------*/
.text-white {
    color: #ffffff !important;
}

.text-gray {
    color: #808080 !important;
}

.text-center {
    text-align: center !important;
}

.b-0 {
    border: 0 !important;
}

.ml-1 {
    margin-left: 4px;
}

.mr-2 {
    margin-right: 8px;
}

.m-auto {
    margin: auto !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.my-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.p-2 {
    padding: 8px !important;
}

.w-100 {
    width: 100% !important
}

.h-100 {
    height: 100% !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-move {
    cursor: move !important;
}

.d-none {
    display: none !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.flex-column {
    flex-direction: column !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

@media (min-width: 575px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .align-items-sm-center {
        .align-items-center();
    }
}


.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bg-transparent {
    background: transparent !important;
}

.visibility-hidden {
    visibility: hidden !important;
}
