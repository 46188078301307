@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "./variables.less";
@import "./custom-style.less";
@import "./helper.less";


.ant-typography:not(.ant-typography-success, .ant-typography-secondary),
.ant-page-header,
.ant-descriptions-item-content,
.ant-descriptions-item-label,
.ant-page-header-heading-title,
.ant-page-header-back-button {
    color: var(--color-text) !important;
}

/* ----------------------------------
Font Face Rule
-------------------------------------*/
.font-face (@font-family, @font-name, @font-weight, @font-style) {
    @font-face {
        font-family: @font-family;
        src: url("../font/@{font-name}.woff2") format("woff2");
        font-weight: @font-weight;
        font-style: @font-style;
        font-display: swap;
    }
}

.font-face("Inter", "Inter-Light", 300, normal);
.font-face("Inter", "Inter-Regular", 400, normal);
.font-face("Inter", "Inter-Medium", 500, normal);
.font-face("Inter", "Inter-SemiBold", 600, normal);
.font-face("Inter", "Inter-Bold", 700, normal);
.font-face("Inter", "Inter-ExtraBold", 800, normal);

* :focus {
    outline: none;
}

html::-webkit-scrollbar-thumb {
    background: #434343;
    border-radius: 16px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

html::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

body {
    line-height: 1.3;

    &::-webkit-scrollbar {
        background-color: var(--color-scrollbar);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-scrollbar-thumb);
    }
}

/* ----------------------------------
Typography
-------------------------------------*/
h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-text);
}

h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-text);
}

h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-text);
}

h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text);
}

h5 {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text);
}

h6 {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text);
}

p:not(.new-invoice-form .ant-empty p):not(p.ant-scroll-number-only-unit) {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text);
}


body {
    font-family: @font-family;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    overflow-x: hidden;
    user-select: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a:not(.ant-btn) {
    color: var(--color-primary);
}

/*------------------------
Custom CSS
-------------------------*/
.text-decoration-hover-underline {
    transition: all .3s;

    &:hover {
        text-decoration: underline;
    }
}

/*------------------------
Color --reset
-------------------------*/
.ant-card-head,
.ant-statistic-content,
.ant-tabs-tab,
.ant-select,
.ant-tabs,
.ant-modal {
    color: var(--color-text);
}

/*------------------------
Statistic
-------------------------*/
.ant-statistic-content-value {
    word-break: break-word;
}

.ant-badge-status-text {
    color: var(--color-text);
}

/*------------------------
Tag
-------------------------*/
.ant-tag {
    color: @grey-color;
    font-size: 13px;
}

.ant-tag-success {
    color: @success-color;
}

.ant-tag-error {
    color: var(--color-btn-danger);
}

.ant-tag-processing {
    color: var(--color-accent-blue);
}

.color-danger-fill {
    fill: var(--color-btn-danger);
}

.color-info-fill {
    fill: var(--color-accent-blue);
}


/*------------------------
Helper Class
-------------------------*/

.text-primary {
    color: var(--color-primary);
}

.text-grey {
    color: var(--color-grey);
}

.bg-light {
    background-color: @light-color;
}

.bg-white {
    background-color: @white-color;
}

.green {
    color: @success-color;
}

.red {
    color: @error-color;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-regular {
    font-weight: 400;
}

.font-weight-medium,
.fw-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

.font-weight-bold {
    font-weight: 700;
}

.border-0 {
    border: 0px !important;
}

.text-right {
    text-align: right !important;
}

.text-reset {
    color: inherit !important;
}

.text-danger {
    color: rgb(var(--color-accent-red)) !important;
}

.text-success {
    color: rgb(var(--color-accent-green)) !important;
}

.m-auto {
    margin: auto !important;
}

.d-flex-center {
    .d-flex();
    .align-items-center();
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.list-style-inline {
    list-style-type: unset;
    padding-left: 0px;
}

.text-default {
    color: var(--color-text);
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

/*------------------------
Badge
-------------------------*/
.order-badge {
    p {
        color: @white-color;
    }
}

/*------------------------
Button
-------------------------*/
a {

    &.ant-btn-lg {
        line-height: 36px;
    }

    &.ant-btn>span {
        vertical-align: middle;
        line-height: 34px;
    }
}

.ant-btn-link {
    color: var(--color-primary);
}

.ant-btn {
    transition: unset;
    text-shadow: none;

    &>.anticon+span,
    &>span+.anticon {
        margin-left: 6px;
    }

    &>span {
        vertical-align: middle;
    }

    &.ant-btn-text {
        color: var(--color-text);
    }
}

.ant-btn:not(.ant-btn-circle):not(.ant-btn-link):not(.modal-cancel-btn):not(.ant-btn-sm) {
    font-size: 15px;
    font-weight: 500;
    padding: 0px 12px;
    height: 36px;
    line-height: 24px;
}

.ant-btn-dark {
    color: @white-color;
    background: @text-color;
    border-color: @text-color;

    &:hover,
    &:focus {
        color: @white-color;
        background: #384462;
        border-color: #384462;
    }
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
    line-height: 36px;
}

.ant-btn:not(.ant-btn-primary):not(.new-invoice-form .ant-btn):not(.ant-btn-dangerous):not(.ant-btn-text.ant-btn-circle):not(.ant-btn-link) {
    color: var(--color-text);
    border-color: var(--color-border-input);
    background: var(--color-btn-default);
}

.ant-btn-background-ghost.ant-btn-dangerous {
    color: rgba(var(--color-accent-red), 1);
    border-color: rgba(var(--color-accent-red), 1);
}

.ant-btn-dangerous,
.ant-btn-dangerous.ant-btn-primary {
    border-color: var(--color-btn-danger) !important;
    background: var(--color-btn-danger) !important;
    color: var(--color-btn-text);

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        color: var(--color-btn-text);
    }
}

.ant-btn-dangerous {
    &.ant-btn-background-ghost {
        color: var(--color-btn-danger);
    }
}

.ant-btn-primary:not(.ant-btn-dangerous) {
    background: var(--color-primary) !important;
    border-color: var(--color-primary);
    color: var(--color-btn-text);
}

.ant-btn-background-ghost.ant-btn-primary:not(.ant-btn-dangerous.ant-btn-primary) {
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary);
    }
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus .ant-btn-primary.ant-btn-background-ghost:not(.ant-btn-dangerous) {
    color: rgba(var(--color-accent-red, 1)) !important;
    border-color: rgba(var(--color-accent-red, 1)) !important;

    &:hover {
        color: rgba(var(--color-accent-red, 1)) !important;
    }
}

/*------------------------
Popover
-------------------------*/
.ant-popover-inner-content,
.ant-popover-inner {
    border-radius: @border-radius-base;
    background: var(--color-btn-default);
    color: var(--color-text);
}

.ant-popover-title {
    color: var(--color-text);
    border-color: var(--color-bg-brand-menu-active);
}

.ant-popover-arrow {
    border-color: var(--color-btn-default) !important;
}

.ant-popover-message-title {
    color: var(--color-text);
}

/*------------------------
Icon
-------------------------*/
i-feather {
    line-height: normal;

    &.if-16 {
        @size: 16px !important;
        width: @size;
        height: @size;
        line-height: @size;
        vertical-align: middle;
    }

    &.if-18 {
        @size: 18px !important;
        width: @size;
        height: @size;
        line-height: @size;
        vertical-align: middle;
    }
}

/*------------------------
Modal
-------------------------*/
.ant-modal-confirm {
    .ant-modal-body {
        padding: 24px;
    }
}

.ant-modal-header {
    border-bottom-color: var(--color-border);
    background: var(--color-bg-body);

    .ant-modal-title {
        color: var(--color-text);
    }
}

.ant-modal-content {
    background: var(--color-bg-body);
    border-radius: 12px;

    .ant-modal-close-x {
        color: var(--color-text);
    }

    .ant-modal-header {
        border-radius: 12px 12px 0 0;
    }
}

.ant-modal-footer {
    padding: 14px 16px;
    border-top-color: var(--color-border);
}

.ant-modal-confirm-body {
    .ant-modal-confirm-title {
        color: var(--color-text);
    }

    .ant-modal-confirm-content {
        color: var(--color-text);
    }
}

.vertical-center-modal {
    .d-flex-center();

    .ant-modal {
        top: 0;
    }
}

.modal-cancel-btn {
    .d-flex-center();
    min-width: 36px !important;
    width: 36px !important;
    height: 36px;
    border-radius: 50%;
    background: #D8DCE5;
    border: 0;
    padding: 0;

    &:hover,
    &:focus {
        background: #e0e5ef;
        transition: background 0.2s ease-in-out;
    }

    svg {
        path {
            &:last-child {
                fill: #747376;
            }
        }
    }
}

.ant-modal-close {
    top: 9.5px;
    right: 9.5px;
}

.ant-modal-close-x {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--color-currency-item);
    }

    .modal-cancel-btn {
        top: 10px;
        padding: 0;
    }

}

.ant-modal-title {
    font-weight: 600;
    font-size: 18px;
}

/*-----------------------------
Custom CSS
------------------------------*/
.scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
        display: none;
    }
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-60 {
    margin-bottom: 60px;

    @media (max-width: 575px) {
        .mb-32();
    }
}

.validation-error:not(.new-invoice-form .validate-error) {
    color: rgb(var(--color-accent-red));
    color: @error-color;
}

.ant-empty-description:not(.new-invoice-form .ant-empty-description) {
    color: var(--color-text) !important;
    opacity: 0.25;
}

.inventory-section {
    .inventory-table-card {
        .ant-table-title {
            background: var(--color-bg-surface);
            padding-right: 0;
        }
    }

    .inventory-action-btn {
        padding-right: 12px !important;
    }
}

.total-amount-due {
    word-break: break-word;

    h2 {
        color: @text-color  !important;
        margin-bottom: 0;

        @media (max-width: 375px) {
            font-size: 28px;
            margin: 6px 0 0;
        }
    }

    @media (min-width: 1600px) {
        text-align: right;
    }
}

.bg-light {
    background: var(--color-bg-light);
}

.d-flex-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    button+button {
        margin-left: 12px;
    }
}

.status--moderate {
    background: rgba(var(--color-accent-blue-rgba), 0.1) !important;
    color: var(--color-accent-blue);

    .ant-select-arrow i {
        color: var(--color-accent-blue) !important;
    }

    svg {
        fill: var(--color-accent-blue);
    }
}

.status--important {
    background: rgba(254, 67, 76, 0.15) !important;
    color: rgb(var(--color-accent-red));

    .ant-select-arrow i {
        color: rgb(var(--color-accent-red)) !important;
    }

    svg {
        fill: rgb(var(--color-accent-red));
    }
}

.status--pending {
    background: rgba(var(--color-accent-yellow), 0.09) !important;
    color: rgb(var(--color-accent-yellow));

    .ant-select-arrow i {
        color: rgb(var(--color-accent-yellow)) !important;
    }

    svg {
        fill: rgb(var(--color-accent-yellow));
    }
}

.status--sold,
.status--completed {
    background: rgba(var(--color-accent-green), 0.25) !important;
    color: rgb(var(--color-accent-green));

    .ant-select-arrow i {
        color: rgb(var(--color-accent-green)) !important;
    }

    svg {
        fill: rgb(var(--color-accent-green));
    }
}

.drag-icon-container {
    .position-relative();
    padding: 0 !important;
    border: none;

    .drag-icon {
        .position-absolute();
        cursor: move;
        left: -25px;
        top: 10px;
        visibility: hidden;
    }

    .cancel-icon {
        .position-absolute();
        .cursor-pointer();
        right: -26.5px;
        top: 14px;

        @media (max-width: 767px) {
            position: relative;
            right: 0;
            top: 0;
        }
    }
}

.logo-file-upload {
    .position-relative();
    border: 1.5px dashed rgba(128, 141, 172, 0.35);
    border-radius: 10px;
    max-width: 284px;
    height: 115px;
    .w-100();
    .d-flex-center();
    .overflow-hidden();
    .cursor-pointer();

    input {
        .position-absolute();
        left: 0;
        top: 0;
        opacity: 0;
        .w-100();
        .h-100();
        .overflow-hidden();
        .cursor-pointer();

        &::-webkit-file-upload-button {
            .cursor-pointer();
            padding: 0px;
            margin: 0px;
        }
    }

    .invoice-upload-delete {
        .position-absolute();
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #dfe2eaed;
        .w-100();
        .h-100();
        .d-flex-center();
        z-index: 1000;
        opacity: 0;
        transform: scale(0);

        span {
            color: @error-color;
        }
    }

    &:hover {
        .invoice-upload-delete {
            opacity: 1 !important;
            transform: scale(1) !important;
            transition: all 0.2s ease-in-out;
        }
    }
}

.total-amount-card {
    padding: 10px;
    max-width: 380px;
    font-weight: 500;
    margin-left: auto;

    @media (max-width:767px) {
        margin-top: 40px;
        padding: 0;
    }

    hr {
        border-color: rgba(128, 141, 172, 0.25);
        opacity: 0.4;
    }

    .total-row {
        &:last-child {
            font-weight: 700;
        }
    }
}

.billed-to-address {
    @media (max-width:1600px) {
        margin-bottom: 32px;
    }
}

.company-phonenumber {
    .d-flex();
    .align-items-center();
    justify-content: flex-end;

    @media (max-width:767px) {
        justify-content: flex-end;
        flex-direction: row-reverse;
        align-items: flex-start;
    }
}

.parent-company-name,
.parent-company-address {
    display: inline-block;
    text-align: right;
    width: 100%;

    @media (max-width:767px) {
        margin-top: 32px;
        text-align: left !important;

        .custom-input-form {
            text-align: left !important;
        }
    }
}

.invoice-table {
    width: 100%;

    tbody {
        vertical-align: top;

        tr {
            td {
                font-weight: 500;
                border-bottom: @border-light;
            }
        }
    }

    .table-header-column {
        font-weight: 600;
        padding: 10px 10px;
        background: transparent;
        border-bottom: @border-light;
        border-top: @border-light;
    }

    .invoice-table-input {
        border: 0;
        width: 100%;
        font-weight: 500;
        padding: 1px;
        overflow: hidden;
        resize: unset;

        &:focus {
            &::placeholder {
                opacity: 0.75;
                transition: opacity 0.2 ease-in-out;
            }
        }

        &::placeholder {
            color: @grey-color;
        }
    }
}

.item-title {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 10px 0;
}

.item-description {
    padding: 4px 10px 10px;
    font-weight: 500;
    font-size: 14px;
}

.item-price,
.item-quantity,
.list-price-total {
    padding: 10px 10px 0;
    font-weight: 500;
    color: @text-color;
}

.invoice-note--description {
    padding: 4px 0;
    white-space: normal;
    word-break: break-word;
}

.pagenot-found-section {
    background-color: var(--color-bg-body);
}

/*------------------------
Alert
-------------------------*/
.ant-alert-info {
    background-color: rgba(73, 44, 155, 0.06);
    border: 1px solid rgba(181, 154, 255, 0.2);
}

.ant-alert-message {
    color: var(--color-text);
}

.ant-alert-info .ant-alert-icon {
    color: var(--color-primary);
}

/*------------------------
Form
-------------------------*/
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--color-bg-body);
    border-color: var(--color-btn-danger) !important;
}

.custom-input-form:not(.invoice-note .custom-input-form):not(.invoice-terms .custom-input-form):not(.invoice-new-custom .custom-input-form) {
    background: transparent;
    .position-relative();

    &:focus {
        background-color: var(--color-bg-surface) !important;
        box-shadow: 0px 0px 0px 2px var(--color-shadow) !important;
        border-radius: 1.5px;
        box-shadow: 0px 0px 0px 2px #b1bdd8;
    }
}

/*------------------------
Table
-------------------------*/
.table-row-checked-bg {
    background: var(--color-progress-bg);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background: transparent;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.ant-table-column-sorters {
    justify-content: flex-start;

    .ant-table-column-title {
        flex: 0 0 auto;
    }

    .ant-table-column-sorter {
        margin: 0 0 0 4px;
    }
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    font-size: 8px;

    &.active {
        color: var(--color-primary);
    }
}

.ant-table-content {
    @media (max-width:767px) {
        overflow-x: auto;
    }
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 12.5px 16px;
}

.notes-table-column {
    max-width: 400px;
}

.custom-table-responsive {
    @media (max-width: 767px) {

        .ant-table-thead>tr>th {
            display: none !important;
        }

        td.ant-table-cell {
            display: flex;
            align-items: center;
        }
    }

    .responsive-header {
        flex: 1 1 50%;
        text-align: left;
        margin-right: 12px;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}

/*------------------------
Settings
-------------------------*/
.upload-image-item {
    .file-name {
        color: var(--color-text);
    }

    &.upload-error {
        .file-name {
            color: rgb(var(--color-accent-red));
        }
    }
}

/*------------------------
Avatar
-------------------------*/

.ant-avatar {
    font-size: 15px;
}

.ant-avatar-string {
    position: relative;
    left: 0;
}

.avatar-bg-primary,
.bg-primary {
    color: var(--color-btn-text);
    background: var(--color-primary);
}

.avatar--32 {
    min-width: 32px;
}

/*------------------------
Invoice
-------------------------*/
.new-invoice-form {
    user-select: text;

    .ant-card-body {
        padding: 40px;

        @media (max-width: 991px) {
            padding: 20px;
        }
    }

    .text-primary {
        color: @primary-color;
    }

    label {
        color: @primary-color;
    }
}

.send-invoice {
    label {
        color: var(--color-primary);
    }

    @media (max-width: 992px) {
        margin-top: 24px;
    }
}

.draft-invoice {
    position: absolute;
    font-size: 15vmax;
    left: 50%;
    bottom: 92%;
    -webkit-text-stroke: #fda82c;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke-width: 1.5px;
    transform: translate(-50%, 100%) rotate(-30deg);

    @media (min-width: 1800px) {
        font-size: 12vmax;
        bottom: 90%;
    }

    @media (min-width: 3000px) {
        font-size: 8vmax;
        bottom: 85%;
    }

    @media (max-width:767px) {
        font-size: 10vmax;
    }

    @media (max-width:475px) {
        font-size: 6vmax;
    }
}

.void-invoice {
    position: absolute;
    font-size: 340px;
    left: 50%;
    bottom: 95%;
    -webkit-text-stroke: red;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke-width: 1.5px;
    transform: translate(-50%, 100%) rotate(-30deg);

    @media (min-width: 1800px) {
        font-size: 420px;
    }

    @media (max-width:1480px) {
        font-size: 240px;
    }

    @media (max-width:575px) {
        font-size: 100px;
    }
}

.new-invoice--report {
    user-select: text;

    .void-invoice {
        z-index: 1;
        font-size: 340px;

        @media (max-width:1480px) {
            font-size: 240px;
        }

        @media (max-width:575px) {
            font-size: 100px;
        }
    }

    .total-amount-due {
        @media (min-width: 768px) {
            text-align: right;
        }
    }
}

.parent-company-name {
    .custom-input-form {
        width: 100%;
    }

    input {
        &::-webkit-autofill {
            background-color: red !important;
        }
    }
}

.total-row {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 4px 0;

    @media (max-width: 575px) {
        align-items: flex-start;
    }

    .total-label {
        width: 50%;
    }

    .total-amount {
        text-align: right;
        width: 50%;
        word-break: break-word;
    }

    .total-input {
        border: 0;
        width: 100%;
        font-weight: 600;
        padding: 10px 10px;
        overflow: hidden;
        resize: unset;

        &:focus {
            &::placeholder {
                opacity: 0.75;
                transition: opacity 0.2 ease-in-out;
            }
        }

        &::placeholder {
            color: rgba(45, 57, 88, 0.35);
        }
    }
}

.invoice-note,
.invoice-terms {
    width: 100%;
    display: inline-block;

    .custom-input-form {
        width: 100% !important;
        max-width: 100% !important;
        resize: unset;
    }
}

/*------------------------
Card
-------------------------*/
.ant-card-head {
    border-color: var(--color-border);
}

.ant-card:not(.new-invoice-form) {
    border-radius: 8px;
    color: var(--color-text);
}

/*------------------------
Dropdown
-------------------------*/
.ant-dropdown-menu {
    background: var(--color-bg-dropdown);

    .ant-dropdown-menu-item {
        color: var(--color-text);
    }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    &:hover {
        background: var(--color-bg-body);
    }
}

.ant-select-item-empty,
.ant-select-dropdown,
.ant-select-item-option {
    background: var(--color-bg-dropdown);
    background-color: var(--color-bg-dropdown) !important;
    color: var(--color-text) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--color-border) !important;
    color: var(--color-text) !important;
}

/*------------------------
Layout
-------------------------*/
.ant-layout-sider-light,
.ant-menu,
.ant-card:not(.new-invoice-form):not(.gross-card) {
    background: var(--color-bg-body);
}

.ant-layout {
    background: var(--color-bg-surface);
}

.ant-layout-header,
.sidebar-logo {
    background: var(--color-bg-header) !important;
}

/*------------------------
Progress
-------------------------*/
.ant-progress-inner {
    background: var(--color-progress-bg);
}

/*------------------------
Dashboard
-------------------------*/
.balance-card {
    .ant-statistic-title {
        color: var(--color-grey);
    }
}

/*------------------------
Menu
-------------------------*/
.ant-menu-submenu>.ant-menu {
    background: var(--color-bg-body);
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: var(--color-bg-brand-menu-active);
}

.ant-menu-submenu-inline {
    &>.ant-menu-submenu-title {
        .ant-menu-submenu-arrow {

            &::before,
            &::after {
                color: var(--color-grey);
                background-image: linear-gradient(to right, var(--color-grey), var(--color-grey));
            }
        }
    }
}

/*------------------------
Tab
-------------------------*/
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-primary);
}

.ant-tabs-top>.ant-tabs-nav::before {
    border-color: var(--color-border);
}

.ant-tabs-ink-bar {
    background: var(--color-primary);
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    background: var(--color-bg-surface);
    border-color: var(--color-border);
    border-radius: 6px 6px 0 0;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: var(--color-border);
    border-color: var(--color-border);
    background: var(--color-primary);
}

.ant-tabs-card {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:not(.ant-tabs-content-holder .ant-tabs-tab-btn) {
        color: var(--color-btn-text);
    }
}

/*------------------------
Table
-------------------------*/
.ant-table:not(.invoice-table--custom .ant-table) {
    background: var(--color-bg-body);
    color: var(--color-text);
    overflow-x: auto;
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
    background: transparent;
}

.ant-table-thead>tr>th:not(.invoice-table--custom .ant-table .ant-table-thead>tr>th) {
    background: var(--color-bg-table-head);
    color: var(--color-text);
    border-color: var(--color-border);
}

.ant-table-tbody>tr>td {
    border-color: rgba(128, 141, 172, 0.15);
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background: transparent;
}

.ant-table-bordered {
    &>.ant-table-container {
        border-color: var(--color-border) !important;

        &>.ant-table-content,
        &>.ant-table-header {
            table {
                border-color: var(--color-border) !important;

                tbody {
                    tr {
                        td {
                            border-color: var(--color-border) !important;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------
Input
-------------------------*/
input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}

input:-webkit-autofill:not(.invoice-new-custom input),
input:-webkit-autofill:hover:not(.invoice-new-custom input),
input:-webkit-autofill:focus:not(.invoice-new-custom input),
textarea:-webkit-autofill:not(.invoice-new-custom input),
textarea:-webkit-autofill:hover:not(.invoice-new-custom input),
textarea:-webkit-autofill:focus:not(.invoice-new-custom input),
select:-webkit-autofill:not(.invoice-new-custom input),
select:-webkit-autofill:hover:not(.invoice-new-custom input),
select:-webkit-autofill:focus:not(.invoice-new-custom input) {
    -webkit-text-fill-color: var(--color-text) !important;
    box-shadow: 0 0 0px 1000px var(--color-bg-body) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.ant-input,
.ant-picker {
    height: 36px;
    padding: 0px 10px;
}

.ant-input-affix-wrapper {
    padding: 0px 8px;
    height: 36px;

    .ant-input {
        height: auto;
        border-radius: 0;
    }
}

.ant-input-prefix {
    margin-right: 6px;
}

.ant-picker-suffix {
    color: var(--color-text);
}

.ant-input:not(.custom-textarea),
.ant-input-affix-wrapper,
.ant-picker:not(.custom-datepicker) {
    background: var(--color-bg-body);
    border-color: var(--color-border-input);
    color: var(--color-text);
}

.ant-input[disabled] {
    background: var(--color-progress-bg);
    border-color: var(--color-border);
    color: var(--color-grey);
    opacity: 0.85;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background: var(--color-bg-body) !important;
    border-color: var(--color-btn-danger) !important;
}

.ant-form-item-label>label {
    color: var(--color-text);
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: rgb(var(--color-accent-red));
}

.ant-form-item-has-error {
    .ant-input {
        background: var(--color-bg-body) !important;
        border-color: rgb(var(--color-accent-red));

        &:hover {
            background: var(--color-bg-body);
        }
    }
}

.custom-input-form:not(.invoice-note .custom-input-form):not(.invoice-terms .custom-input-form):not(.invoice-new-custom .custom-input-form) {
    &:focus {
        background-color: var(--color-bg-surface) !important;
        box-shadow: 0px 0px 0px 2px var(--color-shadow) !important;
    }
}

textarea {
    &.ant-input {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.custom-tel-input {
    .iti.iti--allow-dropdown {
        width: 100%;
    }

    .iti__country-list {
        max-width: 425px;
    }
}

.ant-form-item-explain-error {
    color: var(--color-btn-danger);
}

/*------------------------
Select
-------------------------*/
.ant-select-selector {
    height: 36px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 10px;
    height: 36px;
    line-height: inherit;

    & input {
        height: 34px !important;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 34px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--color-bg-body);
    border: 1px solid var(--color-border-input);
}

.ant-select-multiple .ant-select-selection-item {
    background: var(--color-progress-bg);
    border-color: var(--color-border-input);
}

.ant-select-selection-item-remove {
    svg {
        color: var(--color-text);
    }
}

.ant-select-clear {
    color: var(--color-grey);
    background: var(--color-bg-body);

    &:hover {
        color: var(--color-grey);
    }
}

/*------------------------
Checkbox
-------------------------*/
.ant-checkbox-checked::after {
    border-color: var(--color-primary);
}

.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: var(--color-border);
}

.ant-checkbox-inner {
    border-color: var(--color-border-input);
    background: var(--color-bg-body);
}

.ant-checkbox-wrapper {
    color: var(--color-text);
}

.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: var(--color-disabled-fill);
        border-color: var(--color-disabled-border) !important;
    }
}

/*------------------------
Radio
-------------------------*/
.ant-radio-wrapper {
    color: var(--color-text);
}

.ant-radio-checked {
    &::after {
        border-color: var(--color-primary);
    }
}

.ant-radio-inner {
    background-color: var(--color-bg-body);
    border-color: var(--color-border-input);

    &::after {
        background: var(--color-primary);
    }
}

/*------------------------
Pagination
-------------------------*/
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
    background: var(--color-bg-body);
    border-color: var(--color-border);
    opacity: 0.38;

    i {
        color: var(--color-text);
    }
}

.ant-pagination-item-active {
    background: var(--color-bg-body);
    border-color: var(--color-primary);

    a {
        color: var(--color-primary);
    }
}

.ant-select-arrow {
    i {
        color: var(--color-text);
    }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    background: var(--color-bg-body);
    border-color: var(--color-border);

    i {
        color: var(--color-text);
    }
}

.ant-pagination-item {
    background: var(--color-bg-body);
    border-color: var(--color-border);

    a {
        color: var(--color-text);
    }
}

.ant-pagination-item-active {
    border-color: var(--color-primary);

    a {
        color: var(--color-primary) !important;
    }
}

/*------------------------
Date Picker
-------------------------*/
.ant-picker-input>input:not(.custom-datepicker input) {
    color: var(--color-text);
}

.ant-form-item-has-error .ant-picker:not([disabled]):hover:not(.new-invoice-form .ant-picker):hover {
    background: var(--color-bg-body);
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
    border-color: var(--color-btn-danger);
}

/*------------------------
Angular Text Editor
-------------------------*/
.angular-editor-textarea {
    margin-top: 0 !important;
    border-radius: 0 0 6px 6px;
}

.angular-editor-toolbar {
    border-radius: 6px 6px 0 0;
    border-bottom: 0px !important;
    background: #f9f9f9 !important;
    padding: 6px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.angular-editor-toolbar-set {
    .angular-editor-button {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .angular-editor-button:not(:first-child) {
        border-left: 0px !important;
    }

    .angular-editor-button {
        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child,
        &:nth-child(4) {
            border-radius: 0 4px 4px 0;
        }
    }

    @media @max575 {
        margin-bottom: 8px;
    }
}

.angular-editor {
    min-width: 100% !important;
    width: 100% !important;
}

.angular-editor-toolbar:not(.new-invoice-form .angular-editor-toolbar) {
    background: var(--color-bg-body) !important;
    border-color: var(--color-border) !important;

    .angular-editor-toolbar-set:not(.new-invoice-form .angular-editor-toolbar-se) {
        .angular-editor-button:not(.new-invoice-form .angular-editor-button) {
            background: var(--color-bg-body) !important;
            border-color: var(--color-border) !important;
            color: var(--color-text) !important;
        }
    }
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea:not(.new-invoice-form .angular-editor .angular-editor-wrapper .angular-editor-textarea) {
    border: 1px solid var(--color-border) !important;
    color: var(--color-text);
    border-top: 0px;
}

/*------------------------
CDK
-------------------------*/
.cdk-drag-preview {
    display: table;
}

.cdk-drag-placeholder {
    opacity: 0;
}

/*------------------------
Drawer
-------------------------*/
.ant-drawer-header {
    border-bottom-color: var(--color-border);
    background: var(--color-bg-body);

    .ant-drawer-title {
        color: var(--color-text);
    }
}

.ant-drawer-body,
.ant-drawer-wrapper-body {
    background: var(--color-bg-body);
}

.ant-drawer-footer {
    background: var(--color-bg-body);
    border-top-color: var(--color-border);
}

.ant-drawer-close {
    svg {
        path {
            &:last-child {
                fill: var(--color-text);
            }
        }
    }
}

.ant-drawer-title {
    font-size: 20px;
    font-weight: bold;
}

.ant-drawer-footer {
    padding: 16px 24px;
}

.drawer-cancel-btn {
    border: 0;
    padding: 0 !important;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    .d-flex-center();
}

.add-gear-drawer,
.custom-drawer {
    min-width: 475px;

    @media (max-width: 575px) {
        min-width: 90% !important;
    }

    .ant-drawer-close {
        top: 15px;
        padding: 0;
        right: 15px;
    }
}

/*------------------------
Timeline
-------------------------*/
.ant-timeline-item-tail {
    border-left-color: var(--color-border);
}

.ant-timeline-item-content {
    color: var(--color-text);
}

.timeline-date {
    font-size: 12.5px;
    color: @grey-color;
}

.ant-timeline-item-head {
    background: transparent;
}

/*------------------------
Icons
-------------------------*/
.icon-arrow-back {
    .path-fill {
        fill: var(--color-text);
    }

    svg {
        path {
            &:last-child {
                .path-fill();
            }
        }
    }
}

.btn-icon {
    fill: var(--color-btn-text);
    line-height: 1;
    vertical-align: middle;
}

.phone-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
}

.icon-fill-red {
    fill: rgb(var(--color-accent-red)) !important;
}

.icon-fill-primary {
    fill: var(--color-primary);
}

.icon-fill-grey {
    fill: var(--color-grey);
}

.icon-fill-default {
    fill: var(--color-text);
}

.icon-fill-btn-primary {
    fill: var(--color-btn-text);
}

.custom-status-badge {
    color: var(--color-bg-body);
    border-radius: 30px;
}

.status-pending {
    background-color: rgb(var(--color-accent-yellow));
    border-color: rgb(var(--color-accent-yellow));
    .custom-status-badge();
}

.status-active {
    background-color: rgb(var(--color-accent-green));
    border-color: rgb(var(--color-accent-green));
    .custom-status-badge();
}

.status-inactive {
    background-color: rgb(var(--color-accent-red));
    border-color: rgb(var(--color-accent-red));
    .custom-status-badge();
}

.wallet-card {
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 24px;
    border: 1px solid var(--color-border);

    .wallet-address {
        color: var(--color-text);
        word-break: break-word;
        font-size: 16px;
        font-weight: 600;
    }

    &:hover,
    &:focus,
    &:focus-within {
        .ant-btn {
            visibility: visible;
        }
    }

    i-feather {
        color: var(--color-text);
        width: 18px;
        height: 18px;
    }
}

.gross-card-row {

    .ant-statistic-content-value-decimal {
        font-size: inherit;
    }

    .ant-statistic-title {
        font-size: 20px;
        font-weight: 800;
        margin: 0;
    }

    .ant-statistic-content {
        font-weight: 600;
    }

    .gross-card {
        &.gross--price {
            background: var(--color-bg-card-orange);

            .ant-statistic-title {
                color: #ff733b;
            }
        }

        &.gross--profit {
            background: var(--color-bg-card-green);


            .ant-statistic-title {
                color: #06d053;
            }
        }

        &.gross--cost {
            background: var(--color-bg-card-blue);

            .ant-statistic-title {
                color: #2ac7ff;
            }
        }

        .ant-card-body {
            width: 100%;
            display: flex;
            align-items: center;

            @media (max-width: 420px) {
                padding: 16px;

                .ant-statistic-title {
                    font-size: 16px;
                }

                .ant-statistic-content {
                    font-size: 20px;
                }
            }
        }

        .gross-icon {
            min-width: 56px;
            width: 56px;
            height: 56px;
            border-radius: 100px;
            margin-right: 16px;
            background: var(--color-bg-icon);
        }
    }
}

.loader-item {
    background: var(--color-bg-body);
}

.ant-spin-dot-item {
    background: var(--color-primary);
}

.ant-empty-image {
    max-height: 100px;
    height: auto;
}

/*------------------------
Deal - Table
-------------------------*/
.deal-table {

    .ant-table-container {
        overflow-x: auto;
    }

    .ant-table table {
        table-layout: fixed;
    }

    .mobile-header {
        display: none;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        overflow: hidden;
        padding: 12px 6px;
    }

    .checkbox-td {
        width: 30px;
        padding: 0px;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    tr.ant-table-expanded-row>td,
    tr.ant-table-expanded-row:hover>td {
        background: var(--color-bg-body);
    }

    .ant-table-thead>tr>th {
        background: var(--color-bg-table-head);
        color: var(--color-grey);
    }

    .ant-table-tbody>tr>td {
        vertical-align: middle;
        background: var(--color-bg-body);
        padding-left: 0;
    }

    textarea.ant-input {
        min-height: 36px;
    }

    @media (max-width: 767px) {

        .ant-table-thead>tr>th {
            display: none !important;
        }

        .editable-row-custom>td {
            display: flex;
            padding: 8px 8px;
            border-bottom: 0;

            &:last-child {
                border-bottom: 1px solid var(--color-border);
            }
        }

        .checkbox-td {
            width: 100%;
            padding-left: 15px !important;
        }

        .mobile-header {
            display: inline-block;
            width: 50%;
            min-width: 50%;
            padding: 4px 8px;
        }

        .custom-td-item {
            width: 100%;
        }

        col {
            min-width: 100% !important;
            width: 100% !important;
            display: block;
        }

        .ant-table-tbody>tr>td {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.status-action-select {
    display: inline-block;

    .ant-select {
        border-radius: 100px;
        background: lightgray;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 12px !important;
        line-height: 32px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0px 12px;
        height: 32px;
    }
}

.deal-complete-btn {
    padding: 0px 12px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    border-radius: 32px;
}

.custom-th-cell {
    color: var(--color-grey);
    padding: 13px 16px;
    font-weight: 500;
    text-align: left;
    flex-shrink: 0;
}

.custom-td-cell {
    padding: 13px 16px 13px 0;
    vertical-align: middle;
    background: var(--color-bg-body);
    max-width: 510px;
    width: 100%;
}

.custom-tr-row {
    border-bottom: 1px solid var(--color-border);
    min-height: 60px;

    .custom-th-cell {
        flex-shrink: 0;
    }
}

.custom-tr {
    &>td {
        padding: 0 !important;
    }
}

.note-custom {
    .editable-cell {
        line-height: 28px !important;
        min-height: 40px;
    }
}

.total-sale-value {
    text-align: center;
    padding: 0 20px;
    margin: 5px;
    min-width: 150px;

    .total-price {
        font-weight: 600;
        font-size: 20px;

        @media (max-width: 767px) {
            font-weight: bold;
        }
    }
}

.total-gross-row {
    @media(max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;

        .total-sale-value {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 100%;
        }

        .invoice-action-btn {
            margin: 12px 0 12px 22px;
        }
    }
}

/*------------------------
Invoice
-------------------------*/
.client-docs-item {

    .client-doc-file {
        flex-basis: 50%;
    }

    .client-doc-action {
        .ant-btn {
            &:not(:first-child) {
                margin-left: 12px;
            }
        }
    }
}

.client-document-preview-modal {
    width: 95% !important;

    .ant-modal-body {
        border-radius: 12px;
    }
}

.document-preview-image {
    min-height: 25vh;
    max-height: 90vh;
}

.invoice-new-section {

    .custom-input-form {
        border: 0;
        font-weight: 500;
        max-width: 200px;

        &::placeholder {
            color: var(--color-grey);
        }
    }

    label {
        font-weight: 600;
        display: block;
    }

    .custom-datepicker {
        border: 0px !important;
        outline: unset !important;
        padding: 1px 2px;

        input {
            border: 0px !important;
            outline: unset !important;
            box-shadow: unset !important;

            &::placeholder {
                color: var(--color-grey);
            }
        }
    }
}

.invoice-additional-btn {
    .add-button {
        &:nth-child(2) {
            margin-left: 12px;
        }
    }

    @media (max-width: 390px) {
        .add-button {
            display: block;
            margin-left: 0 !important;
            margin-top: 12px;
            width: 100%;
        }
    }
}

.remove-item-cell {
    .remove-text {
        display: none;

        @media (max-width: 767px) {
            display: inline;
            min-width: 120px;
        }
    }
}

.invoice-send-timeline-icon {
    width: 20px;
    height: 20px;
    background-color: #9e9fa2;
    border-radius: 32px;
}

.invoice-timeline {
    .ant-timeline {
        .ant-timeline-item {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.invoice-action-card {
    .ant-btn {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.timelime-header-title {
    font-weight: 600;
    color: var(--color-text);
}

.new-invoice-row {
    @media (max-width:992px) {
        flex-direction: column-reverse
    }
}

highcharts-chart {
    .highcharts-background {
        fill: var(--color-bg-body);
    }

    .highcharts-axis-labels {
        text {
            fill: var(--color-text) !important;
        }

        &.highcharts-xaxis-labels {
            text-transform: capitalize;
        }
    }

    .highcharts-grid.highcharts-yaxis-grid {
        .highcharts-grid-line {
            stroke: var(--color-chart-grid-line) !important;
        }
    }

    .highcharts-axis-line {
        stroke: var(--color-chart-grid-line) !important;
    }

    .highcharts-credits {
        display: none;
    }

    .highcharts-legend-item {
        text {
            fill: var(--color-text) !important;
            font-weight: 400 !important;
        }
    }

}

// My Team
.team-member-name {
    &:hover {
        span.text-default {
            text-decoration: underline;
            text-decoration-color: var(--color-text);
        }
    }
}


.custom-tag-status {
    border: 0;
    font-size: 13px;
    background: transparent;
    display: flex;
    align-items: center;
}
