@border-radius-base: 4px;
@primary-color: #6A35FF;
@text-color: #2D3958;
@heading-color: #2D3958;
@grey-color: #808DAC;
@color-currency: #007bff;
@light-color: #F8FAFF;
@white-color: #ffffff;
@error-color: #FE434C;
@success-color: #0cc588;
@layout-body-background: #F2F4F9;
@font-family: 'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
@font-variant-base: normal;
@font-feature-settings-base: normal;
@border-light: 1px solid rgba(128, 141, 172, 0.15);
@max1900: ~"(max-width: 1900px)";
@max1199: ~"(max-width: 1199px)";
@max992: ~"(max-width: 992px)";
@max575: ~"(max-width: 575px)";

:root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-brand: #5B27ED;
    --color-brand-400: #B59AFF;
    --color-accent-green: 35, 194, 142;
    --color-accent-red: 254, 67, 76;
    --color-accent-yellow: 236, 211, 46;
    --color-accent-blue: #0E50F6;
    --color-accent-blue-rgba: 14, 80, 246;
    --color-black-400: #2D3958;
    --color-primary: var(--color-brand);
    --color-secondary: #2D3958;
    --color-btn-text: var(--color-white);
    --color-bg-body: var(--color-white);
    --color-bg-dropdown: var(--color-white);
    --color-bg-surface: #F2F4F9;
    --color-bg-header: #2D3958;
    // --color-bg-header: #ffffff;
    --color-bg-light: #F8FAFF;
    --color-bg-brand-text: #f6f0ff;
    --color-progress-bg: #f5f5f5;
    --color-text: var(--color-secondary);
    --color-grey: @grey-color;
    --color-currency: @color-currency;
    --color-bg-icon: var(--color-white);
    --color-border: #f0f0f0;
    --color-border-input: #d9d9d9;
    --color-bg-table-head: #fafafa;
    --color-shadow: #b1bdd8;
    --color-bg-brand-menu-active: #f6f0ff;
    --color-btn-default: var(--color-white);
    --color-btn-danger: @error-color;
    --color-bg-card-orange: #fee4cb;
    --color-bg-card-green: #baf3d2;
    --color-bg-card-blue: #d1f4fd;
    --color-disabled-fill: #F5F5F5;
    --color-disabled-border: #d9d9d9;
    --color-chart-grid-line: #F1F2F6;
    --color-scrollbar: #f1f1f1;
    --color-scrollbar-thumb: #c1c1c1;
    --color-currency-item: #ecebed;
    --color-menu-item-hover: #e7e9ef;
    --color-auth-content-bg: #f8f5ff;
}

[dark] {
    --color-brand: #B59AFF;
    --color-brand-A200: #fcfcff;
    --color-black-400: #222029;
    --color-black-300: #33323A;
    --color-black-200: #3D3B43;
    --color-black-100: #4A4950;
    --color-accent-green: 123, 219, 187;
    --color-accent-red: 255, 142, 148;
    --color-accent-yellow: 244, 229, 130;
    --color-accent-blue: #6E96FA;
    --color-accent-blue-rgba: 110, 150, 250;
    --color-primary: var(--color-brand);
    --color-btn-text: var(--color-black-400);
    --color-bg-body: var(--color-black-300);
    --color-bg-surface: var(--color-black-400);
    --color-bg-header: var(--color-black-300);
    --color-bg-dropdown: var(--color-black-200);
    --color-text: var(--color-brand-A200);
    --color-progress-bg: var(--color-black-200);
    --color-bg-icon: var(--color-black-100);
    --color-bg-light: var(--color-black-200);
    --color-grey: rgba(230, 231, 231, 0.6);
    --color-currency: @color-currency;
    --color-bg-brand-text: #504A64;
    --color-border: var(--color-black-100);
    --color-border-input: var(--color-black-100);
    --color-bg-table-head: var(--color-bg-body);
    --color-shadow: var(--color-black-100);
    --color-bg-brand-menu-active: #5B5274;
    --color-btn-default: var(--color-black-100);
    --color-btn-danger: #FF8E94;
    --color-bg-card-orange: var(--color-bg-body);
    --color-bg-card-green: var(--color-bg-body);
    --color-bg-card-blue: var(--color-bg-body);
    --color-disabled-fill: #4B4A51;
    --color-disabled-border: #707070;
    --color-chart-grid-line: #545454;
    --color-scrollbar: #111111;
    --color-scrollbar-thumb: #434343;
    --color-currency-item: #444349;
    --color-menu-item-hover: #55515e;
    --color-auth-content-bg: #2c2b34;
}
